import React from "react";
import LabeledImageCardsGrid from "components/organisms/LabeledImageCardsGrid";
import MainTitle from "components/molecules/MainTitle/MainTitle";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";

const Prestations = ({ navsData, data }) => {
  const { content, meta } = data;
  const { services_nav: servicesNav } = navsData;
  return (
    <>
      <GeneralHelmet meta={meta} />
      <div className="page-content">
        <div className="services">
          <MainTitle title={content.title} subtitle={content.subtitle} />
          <LabeledImageCardsGrid cards={servicesNav} />
        </div>
      </div>
    </>
  );
};

export default Prestations;

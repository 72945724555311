import React from "react";
import RichText from "components/atoms/RichText/RichText";

const LabeledImageCard = ({ card }) => {
  const src = card.image.card ? card.image.card.url : card.image.url;
  return (
    <>
      <div className="labeled-image-card">
        <div className="labeled-image-card__label">
          <h3>
            <RichText type="text" content={card.label} />
          </h3>
        </div>
        <div className="labeled-image-card__image">
          <picture>
            <img src={src} alt={card.image.alt} />
          </picture>
        </div>
      </div>
    </>
  );
};

export default LabeledImageCard;

import React from "react";
import SlicesZone from "modules/Prismic/SlicesZone";
import MainTitle from "components/molecules/MainTitle/MainTitle";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";

const Supportsphoto = ({ data }) => {
  const { content, meta, slices } = data;
  return (
    <>
      <GeneralHelmet meta={meta} />
      <div className="page-content">
        <div className="support-photo">
          <MainTitle title={content.title} subtitle={content.subtitle} />
          <SlicesZone slices={slices} />
        </div>
      </div>
    </>
  );
};

export default Supportsphoto;

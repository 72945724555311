import React, { useRef } from "react";
import Btn from "components/atoms/Btn/Btn";
import Separator from "components/atoms/Separator/Separator";
import SliderOne from "components/organisms/SliderOne/SliderOne";
import Review from "./Review";
import sliderConf from "./sliderConf";
import RichText from "components/atoms/RichText/RichText";
import {
  isMobile,
  isTablet,
} from "components/helpers/mediaQueries/mediaQueries";

const Reviews = ({ content, ident }) => {
  const [slidesPerView, setSlidesPerView] = React.useState(3);

  React.useEffect(() => {
    if (content.items.length > 2) {
      setSlidesPerView(isMobile ? 1 : isTablet ? 2 : 3);
    } else {
      setSlidesPerView(content.items.length);
    }
  }, []);

  console.log(isMobile);

  return (
    <>
      <div
        className="reviews text-center"
        id={`${content.slice_type}-${ident}`}
      >
        <Separator />
        <span className="fancy-title">Vos petits mots</span>
        <div className="reviews-cards">
          {content.items.length > 3 ? (
            <>
              <div className="reviews-cards__button-prev">
                <i className="icon icon-thin-arrow"></i>
              </div>
              <SliderOne
                sliderConf={{
                  ...sliderConf,
                  loop: content.items.length > 3 ? true : false,
                  slidesPerView: slidesPerView,
                  navigation: {
                    prevEl: ".reviews-cards__button-prev",
                    nextEl: ".reviews-cards__button-next",
                  },
                }}
                slider
                items={content.items}
                ItemComponent={Review}
              />
              <div className="reviews-cards__button-next">
                <i className="icon icon-thin-arrow"></i>
              </div>
            </>
          ) : (
            content.items.map((item, key) => <Review item={item} />)
          )}
        </div>
        {content.primary.button_link.url && (
          <Btn
            to={content.primary.button_link.url}
            type="web"
            appearance="primary"
          >
            <RichText type="text" content={content.primary.button_label} />
          </Btn>
        )}
      </div>
    </>
  );
};

export default Reviews;

import React from "react";
import LoadingIcon from "components/atoms/LoadingIcon";

const Loading = () => {
  return (
    <div className="loading">
      <LoadingIcon />
    </div>
  );
};

export default Loading;

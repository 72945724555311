import React from "react";
import SlicesZone from "modules/Prismic/SlicesZone";
import MainTitle from "components/molecules/MainTitle/MainTitle";
import GeneralHelmet from "components/molecules/GeneralHelmet/GeneralHelmet";

const About = ({ data }) => {
  const { content, meta, slices } = data;
  return (
    <>
      <GeneralHelmet meta={meta} />
      <div className="page-content">
        <div className="about">
          <MainTitle title={content.title} subtitle={content.subtitle} />

          {slices && <SlicesZone slices={slices} />}
        </div>
      </div>
    </>
  );
};

export default About;
